import { transformSyntheticEvent } from "utils/helper-functions/transform-syntetic-event";
import { formatedAdditionalFieldsOnChangePayData } from "../../utils/additional-fields-functions/additional-fields-functions";

export const changePayData = (payload) => {
  const formatedPayload = formatedAdditionalFieldsOnChangePayData(payload);

  return {
    type: "CHANGE-PAY-DATA",
    payload: formatedPayload,
  };
};

export const updatePayData = (payload) => {
  return {
    type: "UPDATE-PAY-DATA",
    payload,
  };
};

export const identifyCardType = (payload) => {
  return {
    type: "IDENTIFY_CARD_TYPE",
    payload,
  };
};
export const createValidationPayError = (payload) => {
  return {
    type: "VALIDATION_ERROR_PAY",
    payload,
  };
};

export const applyTemplate = (payload) => {
  return {
    type: "APPLY_TEMPLATE",
    payload,
  };
};

export const setRequired = (payload) => {
  return {
    type: "SET_REQUIRED",
    payload,
  };
};

export const setWaste = (payload) => {
  return {
    type: "SET_WASTE",
    payload,
  };
};

export const setAddFieldsInfo = (payload) => {
  return {
    type: "SET_ADD_FIELDS_INFO",
    payload,
  };
};

export const setCheckbox = (payload) => {
  payload = transformSyntheticEvent(payload);
  return {
    type: "SET_CHECKBOX",
    payload,
  };
};

export const initMethodAction = (payload) => {
  const formatedFields = formatedAdditionalFieldsOnChangePayData(payload?.fields);
  const newPayload = { ...payload, fields: formatedFields }

  return {
    type: "INIT_METHOD_STATE",
    payload: newPayload,
  };
};

export const setInputEditorCacheValue = (payload) => {
  return {
    type: "SET_INPUT_EDITOR_CACHE_VALUE",
    payload,
  };
};

export const setBdccModal = (payload) => {
  payload = transformSyntheticEvent(payload);
  return {
    type: "SET_BDCC_MODAL",
    payload,
  };
};

export const setCardValidationModal = (payload) => {
  payload = transformSyntheticEvent(payload);
  return {
    type: "SET_CARD_VALIDATION_MODAL",
    payload,
  };
};

export const setPciPraxisGate = (payload) => {
  return {
    type: "SET_PCI_PRAXIS_GATE",
    payload,
  };
};

export const clearNumber = (value = "") => {
  if (!value || typeof value !== "string") return value;
  return value.replace(/\D+/g, "");
};

export const clearSpace = (value = "") => {
  if (typeof value !== "string") return value;
  else {
    value = value.replace(/\s/g, "");
    return value;
  }
};

export const clearSymbols = (value = "") => {
  let newValue = value;

  if (typeof newValue !== "string") return newValue;
  else {
    newValue = clearSpace(newValue);
    newValue = newValue.replace(/[^\d]+/g, "");
    return newValue;
  }
};

export const clearValueForPhoneNumber = (value = "") => {
  if (typeof value !== "string") return value;
  value = value.replace(/[^\d+\-\s\(\)]/g, "");

  if (value.startsWith("+")) {
    value = `+${value.slice(1).replace(/[+]/g, "")}`;
  } else {
    value = value.replace(/[+]/g, "");
  }

  return value.trim();
};

/**
 * Removes non-alphanumeric characters from the given string.
 * @param {string} [value=""] - The string to process.
 * @returns {string} - The string with only alphanumeric characters.
 */
export const clearSpecialCharacters = (value = "") => {
  let newValue = value;

  if (typeof newValue !== "string") return newValue;
  else return newValue.replace(/[^a-zA-Z0-9]+/g, "");
};

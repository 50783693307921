import {
  clearValueForPhoneNumber,
  clearSpace,
  clearSpecialCharacters,
  clearSymbols,
} from "../helper-functions/clear-number";
import { additionalFieldsData } from "../../variables/additional-fields-data";

/**
 * Formats email value by removing spaces.
 * @param {string} email
 * @returns {string}
 */
export const createEmailFormatedValue = (email) => {
  if (typeof email !== "string") return "";
  return clearSpace(email);
};

/**
 * Formats phone value by removing spaces.
 * @param phone
 * @returns {string}
 */
export const createPhoneFormatedValue = (phone) => {
  if (typeof phone !== "string") return "";
  const clearedValue = clearValueForPhoneNumber(phone);

  return clearedValue.replace(
    additionalFieldsData.phone.regexp,
    (match, g1, g2, g3, g4, g5) =>
      [g1, g2, g3, g4, g5].filter(Boolean).join(" "),
  );
};

/**
 * Formats an EVP (Endereço Virtual de Pagamento) value by adding ("-") at specific positions.
 * @param {string} value - The EVP value to format.
 * @returns {string} - The formatted EVP value.
 * @example
 * createEVPFormatedValue("3a2b5e1d8f7c9a6b5d4e123456789abd");
 * Returns "3a2b5e1d-8f7c9-a6b5d-4e123-456789abd"
 */
export const createEVPFormatedValue = (value) => {
  if (typeof value !== "string") return "";
  let newValue = clearSpecialCharacters(value);

  if (newValue.length > 8)
    newValue = newValue.slice(0, 8) + "-" + newValue.slice(8);
  if (newValue.length > 13)
    newValue = newValue.slice(0, 13) + "-" + newValue.slice(13);
  if (newValue.length > 18)
    newValue = newValue.slice(0, 18) + "-" + newValue.slice(18);
  if (newValue.length > 23)
    newValue = newValue.slice(0, 23) + "-" + newValue.slice(23);
  if (newValue.length > 36) newValue = newValue.slice(0, 36);

  return newValue;
};

/**
 * Formats a CPF (Cadastro de Pessoas Físicas) value by adding (".") and ("-") at specific positions.
 * @param {string} value - The CPF value to format.
 * @returns {string} - The formatted CPF value.
 * @example
 * createCPFFormatedValue("12345678901");
 * Returns "123.456.789-01"
 */
export const createCPFFormatedValue = (value) => {
  if (typeof value !== "string") return "";
  let newValue = clearSymbols(value);

  if (newValue.length > 3)
    newValue = newValue.slice(0, 3) + "." + newValue.slice(3);
  if (newValue.length > 7)
    newValue = newValue.slice(0, 7) + "." + newValue.slice(7);
  if (newValue.length > 11)
    newValue = newValue.slice(0, 11) + "-" + newValue.slice(11);

  return newValue;
};

/**
 * Formats a CNPJ (Cadastro Nacional da Pessoa Jurídica) value by adding (".") and ("/") at specific positions.
 * @param {string} value - The CNPJ value to format.
 * @returns {string} - The formatted CNPJ value.
 * @example
 * createCNPJFormatedValue("24221515000180");
 * Returns "24.221.515/0001-80"
 */
export const createCNPJFormatedValue = (value) => {
  if (typeof value !== "string") return "";
  let newValue = clearSymbols(value);

  if (newValue.length > 2)
    newValue = newValue.slice(0, 2) + "." + newValue.slice(2);
  if (newValue.length > 6)
    newValue = newValue.slice(0, 6) + "." + newValue.slice(6);
  if (newValue.length > 10)
    newValue = newValue.slice(0, 10) + "/" + newValue.slice(10);
  if (newValue.length > 15)
    newValue = newValue.slice(0, 15) + "-" + newValue.slice(15);

  return newValue;
};

/**
 * Formats a CNPJ (Cadastro Nacional da Pessoa Jurídica) value by adding (".") and ("/") at specific positions.
 * @param {string} value - The CPF/CNPJ value to format.
 * @returns {string} - The formatted CPF/CNPJ value.
 * @example
 * createCPFCNPJFormatedValue("12345678901");
 * createCPFCNPJFormatedValue("24221515000180");
 * Returns "123.456.789-01"
 * Returns "124.221.515/0001-80"
 */
export const createCPFCNPJFormatedValue = (value) => {
  if (typeof value !== "string") return "";
  let newValue = clearSymbols(value);

  if (newValue.length <= 11) {
    return createCPFFormatedValue(newValue);
  } else {
    return createCNPJFormatedValue(newValue);
  }
};

// Mask functions
/**
 * Masks a CNPJ (Cadastro Nacional da Pessoa Jurídica) number for display.
 * @param {string} cnpj - The CNPJ number to mask.
 * @returns {string} - The masked CNPJ number.
 * @example
 * maskCNPJ("24.221.515/0001-80");
 * Returns masked: "24.************-80"
 */
export const maskCNPJValue = (cnpj) => {
  if (typeof cnpj !== "string") return "";
  return maskString(cnpj);
};

/**
 * Masks a CPF (Cadastro de Pessoas Físicas) number for display.
 * @param {string} cpf - The CPF number to mask.
 * @returns {string} - The masked CPF number.
 * @example
 * maskCPF("353.090.786-30");
 * Returns masked: "353********-30"
 */
export const maskCPFValue = (cpf) => {
  if (typeof cpf !== "string") return "";
  return maskString(cpf);
};

/**
 * Masks CPF (Cadastro de Pessoas Físicas) or CNPJ (Cadastro Nacional da Pessoa Jurídica) number for display.
 * @param {string} cpf - The CPF/CNPJ number to mask.
 * @returns {string} - The masked CPF/CNPJ number.
 * @example
 * maskCPFCNPJValue("353.090.786-30");
 * maskCPFCNPJValue("24.221.515/0001-80");
 * Returns masked: "353********-30"
 * Returns masked: "24.************-80"
 */
export const maskCPFCNPJValue = (cpf_cnpj) => {
  if (typeof cpf_cnpj !== "string") return "";
  return maskString(cpf_cnpj);
};

/**
 * Masks an EVP (Endereço Virtual de Pagamento) number for display.
 * @param {string} evp - The EVP number to mask.
 * @returns {string} - The masked EVP number. Needs to be implemented.
 * @example
 * maskEVP("12345678-1234-1234-1234-123456789012");
 * Returns masked: "12345678-1234-1234-1234-123456789012"
 */
export const maskEVPValue = (evp) => {
  return evp;
};

/**
 * Masks an email for display.
 * @param {string} email - The email to mask.
 * @returns {string} - The masked email.
 */
export const maskEmailValue = (email) => {
  if (typeof email !== "string" || !email?.length) {
    // Expected a string as the email address
    return email;
  }

  const [local, domain] = email.split("@");

  if (!local || !domain) {
    // Invalid email format
    return email;
  }

  const visibleLocalChars = 3;

  const maskedLocal =
    local.length > visibleLocalChars
      ? local.slice(0, visibleLocalChars) +
        "*".repeat(local.length - visibleLocalChars)
      : local + "*".repeat(visibleLocalChars - local.length);

  const domainParts = domain.split(".");
  if (domainParts.length < 2) {
    // Invalid domain format in email address
    return email;
  }
  const tld = domainParts.pop();
  const maskedDomain = "***." + tld;

  return `${maskedLocal}@${maskedDomain}`;
};

/**
 * Masks a phone number for display.
 * @param {string} phone - The phone number to mask.
 * @returns {string} - The masked phone number.
 */
export const maskPhoneValue = (phone) => {
  return maskString(phone);
};

/**
 * Masks a phone number for display.
 * @param {string} phone - The phone number to mask.
 * @returns {string} - The masked phone number.
 */

export const maskString = (string) => {
  if (typeof string !== "string" || !string?.length) {
    // Expected string to mask
    return string;
  }

  let visibleStart = 3;
  let visibleEnd = 3;

  if (visibleStart + visibleEnd > string.length) {
    visibleStart = 1;
    visibleEnd = 1;
  }

  const start = string.slice(0, visibleStart);
  const end = string.slice(string.length - visibleEnd);
  const maskedLength = string.length - (visibleStart + visibleEnd);
  const masked = "*".repeat(maskedLength > 0 ? maskedLength : 2);

  return `${start}${masked}${end}`;
};

import { clearSymbols } from "../helper-functions/clear-number";
import { additionalFieldsData } from "../../variables/additional-fields-data";

const multipliers1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
const multipliers2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

/**
 * Validates a Brazilian CNPJ (Cadastro Nacional da Pessoa Jurídica) number.
 * @param {string} cnpj - The CNPJ number to validate.
 * @returns {boolean} - True if the CNPJ is valid, false otherwise.
 * @example
 * validateCNPJ("24.221.515/0001-80");
 * Returns true / false
 */
export const validateCNPJ = (cnpj) => {
  const maskedValue = additionalFieldsData.cnpj.maskFunction(cnpj);
  if (maskedValue === cnpj && maskedValue.includes("*")) {
    return true;
  }

  if (!additionalFieldsData.cnpj.regexp.test(cnpj)) {
    return false;
  }

  cnpj = clearSymbols(cnpj);

  const calculateDigit = (slice, multipliers) => {
    let sum = 0;
    for (let i = 0; i < multipliers.length; i++) {
      sum += parseInt(slice[i]) * multipliers[i];
    }
    const remainder = sum % 11;
    return remainder < 2 ? 0 : 11 - remainder;
  };

  const digits1 = calculateDigit(cnpj.slice(0, 12), multipliers1);
  const digits2 = calculateDigit(cnpj.slice(0, 13), multipliers2);

  return (
    digits1 === parseInt(cnpj.charAt(12)) &&
    digits2 === parseInt(cnpj.charAt(13))
  );
};

import { clearSymbols } from "../helper-functions/clear-number";
import { validateCPF } from "./validate-cpf";
import { validateCNPJ } from "./validate-cnpj";

export const validateCPFCNPJ = (cpf_cnpj) => {
    const clearedNumber = clearSymbols(cpf_cnpj);

    if (clearedNumber.length <= 11) {
        return validateCPF(cpf_cnpj);
    } else {
        return validateCNPJ(cpf_cnpj);
    }
};

import store from "../../state/store";
import { luhnCheck } from "./luhn-algorithm";
import { round } from "../round-functions/round";
import { clearSpace } from "../helper-functions/clear-number";
import { wordsCount } from "../helper-functions/words-count/words-count";
import { validateEmail } from "./validate-email";
import InternalizationService from "../../context-components/internalization";
import { calculateFee } from "../fee-functions/calculate-comission";
import { dec } from "shared-components/hoc-components/with-decode-pay-title";
import { securityCheck } from "../helper-functions/security-check";
import { eNumber } from "../round-functions/e-number";
import Callbacks from "../../context-components/callbacks";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  additionalFieldsData,
  errorMsg,
} from "../../variables/additional-fields-data";

const { lang, langBack } = new InternalizationService();
const { readCallbacks } = new Callbacks();

export const customValidation = (fields, required) => {
  if (fields === undefined) return {};
  let errors = {};
  const {
    gatewayInfo: { method },
    currentGateway: { additional_fields },
    payInfo: { template },
    payData: {
      is_customer_email_changed,
      is_email_changed,
      is_customer_phone_changed,
      is_phone_changed,
    },
    profileInfo: { remove_customer_data },
  } = store.getState();

  const changeErrors = (name, value) => {
    errors = {
      ...errors,
      [name]: value,
    };
  };

  const customerValidation = (el, value) => {
    const { fieldsValidation = {} } = readCallbacks();
    let error = false;
    if (!fieldsValidation[el]) return;

    const { min, max } = fieldsValidation?.[el];
    if (!isNaN(+min) && value.length < +min)
      error = lang("minimumLength", { length: min });
    if (!isNaN(+max) && value.length > +max)
      error = lang("maximumLength", { length: max });
    return error;
  };

  Object.keys(fields).map((el) => {
    let value = fields[el];

    if (value === undefined) return;
    if (value === null) value = "";

    if (method !== "card-method" && template[el]) return changeErrors(el, null);

    if (!clearSpace(value) && required.includes(el)) {
      // for pix_value field, we need to show the error message from the local data
      if (el === "pix_value") return changeErrors(el, errorMsg);

      return changeErrors(el, langBack(`cannotBeBlank`));
    }

    if (securityCheck(value))
      return changeErrors(el, langBack(`Incorrect value`));

    const customValidationError = customerValidation(el, value);
    if (customValidationError) return changeErrors(el, customValidationError);

    if (el === dec(1)) {
      if (fields.template_token || fields.card_token || fields.pci)
        return changeErrors(el, null);
      if (!luhnCheck(value)) return changeErrors(el, lang("cardIsNotValid"));
      return changeErrors(el, null);
    }
    if (el === dec(2)) {
      if (fields.template_token || fields.card_token)
        return changeErrors(el, null);
      if (value.length !== 5)
        return changeErrors(el, lang("invalidDateFormat"));
      else if (value.substring(0, 2) === "00") {
        return changeErrors(el, lang("invalidDateFormat"));
      } else if (value.length === 5) {
        const month = value.slice(0, 2);
        const year = value.slice(3, 5);
        if (+month > 12) return changeErrors(el, lang("invalidDateFormat"));
        const date = new Date(`20${year}`, +month);
        const diff = new Date() - date;
        if (+diff > 0) return changeErrors(el, lang("newInvalidExpireData"));
      }
    }
    if (el === dec(0)) {
      if (value.length < 3)
        return changeErrors(el, lang(`invalidInputFormat`, { length: 3 }));
    }
    if (el === dec(3)) {
      if (wordsCount(value) < 2)
        return changeErrors(el, lang(`cardHolderInvalid`));
    }
    if (el === "country") {
      value = clearSpace(value);
      if (value.length > 2)
        return changeErrors(el, lang("maximumLength", { length: 2 }));
    }
    if (el === "email" || el === dec(5)) {
      if (el === dec(5) && remove_customer_data && !is_customer_email_changed)
        return changeErrors(el, null);
      if (
        el === "email" &&
        remove_customer_data &&
        ((!is_email_changed && value) || is_email_changed === 0)
      )
        return changeErrors(el, null);
      if (customerValidation("email", value))
        return changeErrors(el, customerValidation("email", value));
      value = clearSpace(value);
      if (!value) value = "";
      if (!validateEmail(value))
        return changeErrors(el, lang(`emailIsNotValid`));
    }
    if (el === "phone" || el === dec(4)) {
      if (el === dec(4) && remove_customer_data && !is_customer_phone_changed) {
        return changeErrors(el, null);
      }
      if (
        el === "phone" &&
        remove_customer_data &&
        !is_phone_changed &&
        ((!is_email_changed && value) || is_email_changed === 0)
      ) {
        return changeErrors(el, null);
      }
      if (customerValidation("phone", value)) {
        return changeErrors(el, customerValidation("phone", value));
      }
      value = clearSpace(value);
      if (!value) value = "";
      let phoneValid = value.match(/^[0-9\s-\+]{5,50}$/);
      if (!phoneValid) {
        return changeErrors(el, lang(`phoneIsNotValid`));
      }
    }
    if (el === "city") {
      value = clearSpace(value);
      if (value.length > 50)
        return changeErrors(el, lang("maximumLength", { length: 50 }));
    }
    if (el === "state") {
      value = clearSpace(value);
      if (value.length > 50)
        return changeErrors(el, lang("maximumLength", { length: 50 }));
    }
    if (el === "zip") {
      value = clearSpace(value);
      if (value.length > 50)
        return changeErrors(el, lang("maximumLength", { length: 50 }));
    }
    if (el === "address") {
      value = clearSpace(value);
      if (value.length > 100)
        return changeErrors(el, lang("maximumLength", { length: 100 }));
    }
    if (el === "full_name") {
      value = clearSpace(value);
      if (value.length > 100)
        return changeErrors(el, lang("maximumLength", { length: 100 }));
    }

    // validation for new additional fields by type
    let field = additional_fields.find((field) => field.name === el);
    field = additionalFieldsData?.[field?.type];

    if (field) {
      value = clearSpace(value);
      const isValid = field.validationFunction(value);
      if (!isValid) {
        return changeErrors(el, langBack(field.uiTexts.error));
      }
    }

    return changeErrors(el, null);
  });
  return errors;
};

export const validateConvertAmount = (amount, currency) => {
  const {
    profileInfo: {
      customer: { balance },
      base_currency,
      transaction_type,
    },
    gatewayInfo: { is_auth },
    virtualSettings: { override_limits },
  } = store.getState();
  const {
    currentGateway: { payment_information: { currency_list = [] } = {}, fees },
  } = store.getState();
  const rate = currency_list[currency];

  if (currency === "" || currency === null)
    return {
      currency: lang(`cannotBeBlank`),
      amount: amount === "" || amount === null ? lang(`cannotBeBlank`) : null,
    };

  if (amount === undefined || !rate) return {};

  let min = rate.min_amount;
  let max = rate.max_amount;

  // Get calculated fee object
  let feeObject =
    fees && fees[currency]
      ? calculateFee(fees, amount, currency, transaction_type)
      : null;
  let fee = feeObject ? feeObject.fee : null;

  // If fee is not included, subtract it from max
  max = fee && max && fee.is_included !== 1 ? max - fee.fee_value : max;

  if (amount === "" || amount === null)
    return { amount: lang(`cannotBeBlank`), currency: null };
  if (amount === ".") return { amount: lang("invalidAmount"), currency: null };

  // If it's percentage fee we should check if it exceeds 20% of the amount, not for the fixed fee (flat_fee_amount)
  if (
    fee &&
    fee.percentage_fee &&
    fee.is_included === 1 &&
    amount * 0.2 < fee.fee_value
  ) {
    return { amount: "Fee should not exceed 20% of the amount" };
  }
  let convertAmount = round(Number(amount) / rate.rate);

  if (min && +amount < +min && !override_limits && !is_auth) {
    return {
      amount: `${lang("minimumValueIs")} ${eNumber(min)}.`,
      currency: null,
    };
  }
  if (max && +amount > +max && !override_limits && !is_auth) {
    return {
      amount: `${lang("maximumValueIs")} ${eNumber(max)}.`,
      currency: null,
    };
  }
  // Check if balance is less than amount (pay-out)
  if (
    balance !== null &&
    parseFloat(balance) < convertAmount &&
    transaction_type === "pay-out"
  ) {
    return {
      amount: `${lang("pleaseEnterPayout")} ${eNumber(
        balance,
      )} ${base_currency}`,
      currency: null,
    };
  }

  return { amount: null, currency: null };
};

export const validateConvertDepositAmount = (amount, currency) => {
  let {
    profileInfo: {
      customer: { balance },
      base_currency,
      transaction_type,
    },
    payData: { totalCharge },
    virtualSettings: { override_limits },
    gatewayInfo: { is_auth },
  } = store.getState();
  const {
    currentGateway: {
      payment_information: {
        currency_list = [],
        rate: baseRate,
        min_amount,
        crypto,
        max_amount,
      } = {},
      fees,
    },
  } = store.getState();

  const rate = currency_list[base_currency] || {
    rate: baseRate,
    crypto,
    min_amount,
    max_amount,
  };

  if (currency === "" || currency === null)
    return {
      currency: lang(`cannotBeBlank`),
      amount: amount === "" || amount === null ? lang(`cannotBeBlank`) : null,
    };

  if (totalCharge === "" || totalCharge === null)
    return {
      currency: lang(`cannotBeBlank`),
      totalCharge:
        totalCharge === "" || totalCharge === null
          ? lang(`cannotBeBlank`)
          : totalCharge,
    };

  if (amount === undefined || !rate) return {};

  let min = rate.min_amount;
  let max = rate.max_amount;

  // Get calculated fee object
  let feeObject =
    fees && fees[currency]
      ? calculateFee(fees, amount, currency, transaction_type)
      : null;
  let fee = feeObject ? feeObject.fee : null;

  // If fee is not included, subtract it from max
  max = fee && max && fee.is_included !== 1 ? max - fee.fee_value : max;

  if (amount === "" || amount === null)
    return { amount: lang(`cannotBeBlank`), currency: null };
  if (amount === ".") return { amount: lang("invalidAmount"), currency: null };

  // NOTE: If it's percentage fee we should check if it exceeds 20% of the amount, not for the fixed fee (flat_fee_amount)
  if (
    fee &&
    fee.percentage_fee &&
    fee.is_included === 1 &&
    amount * 0.2 < fee.fee_value
  ) {
    return { amount: "Fee should not exceed 20% of the amount" };
  }

  if (min && +amount < +min && !override_limits && !is_auth) {
    return {
      amount: lang(
        transaction_type === "pay-in"
          ? "newDepositAmountMustBe"
          : "newWithdrawAmountMustBe",
        {
          currency: getSymbolFromCurrency(base_currency) || "",
          min: eNumber(min),
          max: eNumber(max),
        },
      ),
      currency: null,
    };
  }

  if (max && +amount > +max && !override_limits && !is_auth) {
    return {
      amount: lang(
        transaction_type === "pay-in"
          ? "newDepositAmountMustBe"
          : "newWithdrawAmountMustBe",
        {
          currency: getSymbolFromCurrency(base_currency) || "",
          min: eNumber(min),
          max: eNumber(max),
        },
      ),
      currency: null,
    };
  }

  let convertAmount = round(Number(amount) / rate.rate);
  if (
    balance !== null &&
    transaction_type === "pay-out" &&
    (convertAmount === ""
      ? parseFloat(balance) < parseFloat(amount)
      : parseFloat(balance) < convertAmount)
  ) {
    return {
      amount: `${lang("pleaseEnterPayout")} ${eNumber(
        balance,
      )} ${base_currency}`,
      currency: null,
    };
  }

  return { amount: null, currency: null, totalCharge: null };
};

/**
 * Get the differences between two arrays.
 * @param {Array} oldArray - The original array.
 * @param {Array} newArray - The new array to compare with the original array.
 * @returns {Object} An object representing the differences where keys are the array elements and values are either 'added' or 'removed'.
 */
export const getArrayDifferences = (oldArray, newArray) => {
    const oldSet = new Set(oldArray);
    const newSet = new Set(newArray);
    const changes = {};

    for (const value of newSet) {
        if (!oldSet.has(value)) changes[value] = 'added';
    }

    for (const value of oldSet) {
        if (!newSet.has(value)) changes[value] = 'removed';
    }
    return changes;
};

/**
 * Get the added and removed values between two arrays.
 * @param {Array} oldArray - The original array.
 * @param {Array} newArray - The new array to compare with the original array.
 * @returns {Object} An object with two arrays: `removedFields` and `addedFields`.
 */
export const getChangedValues = (oldArray, newArray) => {
    const changedValues = getArrayDifferences(oldArray, newArray);
    const removedFields = Object.keys(changedValues).filter(key => changedValues[key] === 'removed');
    const addedFields = Object.keys(changedValues).filter(key => changedValues[key] === 'added');

    return {
        removedFields,
        addedFields
    }
}

/**
 * Get the fields that are not required based on changes between two arrays.
 * @param {Array} notRequiredArray - The array of fields in not required array.
 * @param {Array} oldArray - The original array.
 * @param {Array} newArray - The new array to compare with the original array.
 * @returns {Array} An array of fields that are not required.
 */
export const getNotRequiredFields = (notRequiredArray = [], oldArray = [], newArray = []) => {
    let diffArray = notRequiredArray;
    const {removedFields, addedFields} = getChangedValues(oldArray, newArray);

    if (removedFields.length) diffArray = [...diffArray, ...removedFields];
    if (addedFields.length) diffArray = diffArray.filter(field => !addedFields.includes(field));

    return [...new Set(diffArray)];
};

import {additionalFieldsData} from "../../variables/additional-fields-data";

/**
 * Validates an EVP (Endereço Virtual de Pagamento), a unique random-generated Pix key.
 * @param {string} evp - The EVP to validate.
 * @returns {boolean} - True if the EVP is valid, false otherwise.
 * @example
 * validateEVP("12345678-1234-1234-1234-123456789012");
 * Returns true / false
 */
export const validateEVP = (evp) => {
  return additionalFieldsData.evp.regexp.test(evp);
};

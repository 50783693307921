import { clearSpecialCharacters } from "../helper-functions/clear-number";
import { isTest } from "variables/host";
import {
  additionalFieldsData,
  bankAccountPixV2Payout,
  DEFAULT_BANK_PIX_KEY_TYPE,
  DEFAULT_PIX_KEY_TYPE,
  keysBankAccountPixV2Payout,
} from "../../variables/additional-fields-data";
import store from "../../state/store";
import { checkFormat } from "../validations-functions/change-format-methods";
import { parsingSynthetics } from "../helper-functions/transform-syntetic-event";

const checkIsDefaultPixKeyType = (pixKeyType) => {
  return [DEFAULT_PIX_KEY_TYPE, DEFAULT_BANK_PIX_KEY_TYPE].includes(pixKeyType);
};

export const checkIsEmailOrPhone = (field) => {
  return (
    ["email", "phone"].includes(field?.name) ||
    ["email", "phone"].includes(field?.type)
  );
};

export const prioritizeAccountIdentifier = (additionalFields = []) => {
  const accountIdentifierField = additionalFields.find(
    (field) => field.is_account_identifier === 1,
  );

  if (!accountIdentifierField) {
    return additionalFields;
  } else {
    const filteredAdditionalFields = additionalFields.filter(
      (field) => field.is_account_identifier !== 1,
    );
    return [accountIdentifierField, ...filteredAdditionalFields];
  }
};

/**
 * Formats values in the payload object based on additional fields data.
 * @param {Object.<string, *>} payload - The input object with key-value pairs.
 * @param additionalFieldType - The type of the additional field.
 * @returns {Object.<string, *>} The new payload with formatted values.
 */
export const createFormattedValueForAdditionalFields = (
  payload,
  additionalFieldType,
) => {
  if (typeof payload !== "object" || payload === null) {
    if (isTest) console.error("The payload must be an object");
    return payload;
  }

  const newPayload = { ...payload };

  Object.entries(newPayload).forEach(([key, value]) => {
    const currentField =
      additionalFieldsData?.[key] ||
      additionalFieldsData?.[additionalFieldType];

    if (currentField) {
      const { formatFunction, length, isUseClearFunction, maskFunction } =
        currentField;

      const maskedValue = maskFunction(value);
      const isMasked = maskedValue === value && maskedValue?.includes("*");

      if (isMasked) return (newPayload[key] = value);

      const newValue = isUseClearFunction
        ? clearSpecialCharacters(value)
        : value;

      // the [key] is used here because in the case of the PIX payment method (payout)
      // the name of the input field does not match the type but must be validated
      newPayload[key] =
        newValue?.length >= length
          ? formatFunction(newValue.slice(0, length))
          : formatFunction(newValue);
    }
  });

  return newPayload;
};

export const preparePixV2Fields = (data) => {
  const newData = { ...data };

  if (newData?.additional_fields?.length > 0) {
    newData.additional_fields = newData.additional_fields?.map((field) => {
      if (field.transaction_type === "deposit") return field;

      if (field.name === "pix_key_type") {
        field.type = field.type !== "select" ? "select" : field.type;
        // use for create new template with default value
        field.pixKeyTypeDefaultFromBackend = field.default_value;
        field.default_value = field?.default_value || DEFAULT_PIX_KEY_TYPE;
      }
      return field;
    });
  }

  return newData;
};

export const processPixV2Payout = (payload) => {
  const {
    profileInfo: { transaction_type },
    currentGateway: { payment_method_key, additional_fields },
    payData,
    payInfo: { template },
  } = store.getState();

  const isPixV2 = payment_method_key === "pixv2";
  const isPayOut = transaction_type === "pay-out";

  const defaultPixKeyType =
    additional_fields.find((field) => field.name === "pix_key_type")
      ?.pixKeyTypeDefaultFromBackend || DEFAULT_PIX_KEY_TYPE;

  const savedPixKeyType = payData?.pix_key_type;

  const resetPixKey = () => {
    const hasTemplate = Boolean(Object.keys(template || {}).length);
    const hasPixKeyType = payload?.pix_key_type;

    if (hasTemplate) {
      payload.pix_key_type = template?.pix_key_type || DEFAULT_PIX_KEY_TYPE;
    } else if (!hasPixKeyType && Object.keys(payload)?.length !== 1) {
      if (!savedPixKeyType) payload.pix_key_type = defaultPixKeyType;
    }
  };

  const processPixKeyType = (pixKeyType) => {
    if (Object.keys(payload)?.length === 1) payload.pix_value = "";
    if (!pixKeyType) payload.pix_key_type = defaultPixKeyType;

    resetPixKey();

    if (isPixV2 && isPayOut) {
      return setPixValueInPayloadForPayout(
        payload,
        pixKeyType || payload.pix_key_type,
        payload?.pix_document,
      );
    }

    return setPixValueInPayloadDefault(payload, pixKeyType);
  };

  if (payload?.pix_key_type !== undefined) {
    payload = processPixKeyType(payload.pix_key_type);
  } else if (savedPixKeyType) {
    resetPixKey();

    payload = isPayOut
      ? setPixValueInPayloadForPayout(
          payload,
          savedPixKeyType,
          payload?.pix_document,
        )
      : setPixValueInPayloadDefault(payload, savedPixKeyType);
  }

  // used to set the default value of the pix_key_type field if it is not present in the payload
  // template created in the pay in transaction
  if (
    Object.keys(payload).length > 1 &&
    payload?.template_token &&
    !payload?.pix_key_type &&
    isPixV2 &&
    isPayOut
  ) {
    payload.pix_key_type = DEFAULT_PIX_KEY_TYPE;

    payload = setPixValueInPayloadForPayout(
      payload,
      payload.pix_key_type,
      payload?.pix_document,
    );
  }

  // TODO: remove if cpf and cnpj fields are not coming from backend
  if (["cpf", "cnpj"].includes(payload?.pix_key_type)) {
    payload.pix_key_type = DEFAULT_PIX_KEY_TYPE;
  }

  return payload;
};

export const formatedAdditionalFieldsOnChangePayData = (data) => {
  let payload = { ...data };

  if (payload?.template_token && !payload?.email) {
    const {
      profileInfo: {
        customer: { email_customer },
      },
      currentGateway: { additional_fields },
    } = store.getState();

    const emailField = additional_fields.find(
      (field) => field.type === "email",
    );

    payload.email = emailField?.default_value || email_customer || "";
  }

  const additionalFieldType = payload?.target?.dataset?.additionalFieldType;

  payload = checkFormat(parsingSynthetics(payload));
  payload = createFormattedValueForAdditionalFields(
    payload,
    additionalFieldType,
  );

  const {
    profileInfo: { transaction_type },
    currentGateway: { payment_method_key },
  } = store.getState();

  const isPixV2 = payment_method_key === "pixv2";
  const isPayOut = transaction_type === "pay-out";

  if (isPixV2) {
    const removeBankFields = () => {
      keysBankAccountPixV2Payout.forEach((name) => {
        payload[name] = undefined;
      });
    };

    const pixKeyTypeExist = payload?.pix_key_type !== undefined;

    if (pixKeyTypeExist) {
      if (payload?.pix_key_type === DEFAULT_BANK_PIX_KEY_TYPE) {
        keysBankAccountPixV2Payout.forEach((name) => {
          if (!payload[name]) payload[name] = "";
        });
      } else {
        removeBankFields();
      }
    } else {
      if (payload?.template_token) {
        // remove pix_key_type from store
        payload.pix_key_type = undefined;
        removeBankFields();
      }
    }
  }

  if (payload?.isTrusted !== undefined) payload.isTrusted = undefined;

  if (isPixV2 && isPayOut) return processPixV2Payout(payload);

  return payload;
};

const setPixValueInPayloadForPayout = (payload, pixKey, pixDocument) => {
  const isOneOfDefaultPixKeyTypes = checkIsDefaultPixKeyType(pixKey);

  if (isOneOfDefaultPixKeyTypes && pixDocument !== undefined) {
    payload.pix_value = pixDocument;
  }

  return payload;
};

const setPixValueInPayloadDefault = (payload, pixKey) => {
  const isOneOfDefaultPixKeyTypes = checkIsDefaultPixKeyType(pixKey);

  if (isOneOfDefaultPixKeyTypes && payload?.cpf_cnpj) {
    payload.pix_value = payload.cpf_cnpj;
  }

  return payload;
};

export const processPixV2ElementForRender = (el, options) => {
  const { pix_key_type, payInfo, templates, parsedPixKeyTypeData } = options;

  const isEditableExist = el?.is_editable !== undefined;
  const isBankAccountType = pix_key_type === DEFAULT_BANK_PIX_KEY_TYPE;
  const isCpfCnpjType = pix_key_type === DEFAULT_PIX_KEY_TYPE;
  const isPixValueKey = el.name === "pix_value";

  if (
    (isPixValueKey && isCpfCnpjType) ||
    (isPixValueKey && isBankAccountType) ||
    (keysBankAccountPixV2Payout.includes(el.name) && !isBankAccountType)
  ) {
    return null;
  }

  if (el.name === "pix_key_type") {
    el.label = el?.label || "Tipo de chave pix";

    if (isEditableExist) {
      el.is_mutable =
        el.is_editable === 1
          ? "keep_editable"
          : payInfo?.isNewEditableTemplate
            ? "keep_editable"
            : "manual_readonly";
    } else {
      const isNotNewAndNoTemplates =
        !payInfo?.isNewEditableTemplate && !Object.keys(templates).length;

      el.is_mutable = isNotNewAndNoTemplates
        ? "keep_editable"
        : payInfo?.isNewEditableTemplate
          ? "keep_editable"
          : "manual_readonly";
    }
  }

  if (isPixValueKey) {
    const currentLabel = parsedPixKeyTypeData?.[pix_key_type];
    const field = additionalFieldsData?.[pix_key_type];

    if (field) {
      const { type, uiTexts } = field;

      el.type = type;
      el.label = currentLabel || uiTexts.label;
      el.placeholder = uiTexts.placeholder;
    }
  }

  if (isBankAccountType) {
    const field = bankAccountPixV2Payout?.[el.name];

    if (field) {
      const { uiTexts } = field;

      el.label = el?.label || uiTexts.label;
      el.placeholder = uiTexts.placeholder;
    }
  }

  if (el.name === DEFAULT_PIX_KEY_TYPE || el.type === DEFAULT_PIX_KEY_TYPE) {
    const { uiTexts } = additionalFieldsData[DEFAULT_PIX_KEY_TYPE];

    el.label = el?.label || uiTexts.label;
    el.placeholder = uiTexts.placeholder;
  }

  return el;
};

import {
    customValidation,
    validateConvertAmount,
    validateConvertDepositAmount,
} from "./validation-methods";
import {isTwoFieldsAmount} from "variables/is-two-fields-amount";
import { sendOpenSearchLogs } from "utils/helper-functions/send-open-search-logs.ts";


export const validation = (data, required, type = "custom-method") => {
    return validationType(data, required, type);
};

const validationType = (data = {}, required, type) => {
    const {amount, currency, ...fields} = data;
    
    switch (type) {
        case "custom-method":
            return {
                ...customValidation(fields, required),
                ...validateConvertAmount(amount, currency, required),
            };
        case "amount":
            return {
                ...validateConvertAmount(amount, currency),
            };
        case "depositAmount":
            return {
                ...customValidation(fields, required),
                ...validateConvertDepositAmount(amount, currency, required),
            };
        case "manual-bank-wire":
            return {
                ...customValidation(fields, required),
                ...(isTwoFieldsAmount ? validateConvertDepositAmount(amount, currency) : validateConvertAmount(amount, currency)),
            }
        
        default:
            const errorText = "function validation must have 'type' argument";

            sendOpenSearchLogs({
                event_group: "cashier_validation_type",
                error_data: errorText
            });

            console.error(errorText);
            return {};
    }
};

import { getNotRequiredFields } from "../../utils/helper-functions/array-comparison";

const applyTemplate = (template) => {
  if (template) return template;
  return {};
};

const changePayInfo = (state, action) => {
  if (state === undefined) {
    return {
      initMethod: false,
      template: {},
      templateKey: {},
      checkbox: true,
      required: [],
      waste: [],
      visibleFields: null,
      changedPayData: null,
      manualRedirect: false,
      inputEditorCachedValue: {},
      errorsInfo: {},
      showBdccModal: false,
      showCardValidationModal: false,
      pciPraxisGate: {},
      isNewEditableTemplate: false, // use for additional fields (with key is_editable)
    };
  }

  switch (action.type) {
    case "CREATE_CURRENT_GATEWAY":
      return {
        ...state.payInfo,
        initMethod: false,
        templateKey: {},
        template: {},
        checkbox: true,
        visibleFields: null,
        required: [],
        waste: [],
        changedPayData: null,
        manualRedirect: false,
        errorsInfo: {},
      };
    case "CREATE_CURRENT_GATEWAY_FROM_AMOUNT_PAGE":
      return {
        ...state.payInfo,
        initMethod: false,
        templateKey: {},
        template: {},
        checkbox: true,
        visibleFields: null,
        required: [],
        waste: [],
        changedPayData: null,
        manualRedirect: false,
        errorsInfo: {},
      };
    case "APPLY_TEMPLATE":
      const { value, key } = action.payload;
      return {
        ...state.payInfo,
        template: applyTemplate(value),
        templateKey: key,
        isNewEditableTemplate: !key && !value,
        inputEditorCachedValue: {},
      };
    case "SET_CHECKBOX":
      return {
        ...state.payInfo,
        checkbox: !state.payInfo.checkbox,
      };
    case "SET_REQUIRED":
      let notRequiredFields = getNotRequiredFields(
        state.payInfo.notRequiredFields,
        state.payInfo.required,
        action.payload,
      );

      return {
        ...state.payInfo,
        required: action.payload,
        notRequiredFields,
      };
    case "SET_WASTE":
      return {
        ...state.payInfo,
        waste: action.payload,
      };
    case "CHANGE-PAY-DATA":
      return {
        ...state.payInfo,
        changedPayData: action.payload,
      };
    case "UPDATE-PAY-DATA":
      return {
        ...state.payInfo,
        changedPayData: {
          ...state.payInfo.changedPayData,
          [action.payload.name]: action.payload.value,
        },
      };
    case "SET_ADD_FIELDS_INFO":
      return {
        ...state.payInfo,
        ...action.payload,
      };
    case "INIT_METHOD_STATE":
      return {
        ...state.payInfo,
        waste: action.payload.waste,
        required: action.payload.required,
        type: action.payload.type,
        initMethod: true,
        inputEditorCachedValue: {},
        showBdccModal: false,
        showCardValidationModal: false,
        pciPraxisGate: {},
      };
    case "SET_INPUT_EDITOR_CACHE_VALUE":
      return {
        ...state.payInfo,
        inputEditorCachedValue: {
          ...state.payInfo.inputEditorCachedValue,
          ...action.payload,
        },
      };
    case "SET_BDCC_MODAL":
      return {
        ...state.payInfo,
        showBdccModal: !state.payInfo.showBdccModal,
      };
    case "SET_CARD_VALIDATION_MODAL":
      return {
        ...state.payInfo,
        showCardValidationModal: !state.payInfo.showCardValidationModal,
      };
    case "SET_PCI_PRAXIS_GATE":
      return {
        ...state.payInfo,
        pciPraxisGate: { ...action.payload },
      };
    default:
      return state.payInfo;
  }
};

export { changePayInfo };

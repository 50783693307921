import { validation } from "utils/validations-functions/main-validation";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { createCurrentGateway, updateGateways } from "state/actions";
import { changePayData, createValidationPayError } from "state/actions/pay-data-actions";
import { isTwoFieldsAmount } from "variables/is-two-fields-amount";
import { resetCardToObSettings } from 'state/actions/index';

const useBackToCardButton = (props = {}) => {
    const { setIsLoading = () => {}, history } = props;

    const dispatch = useDispatch();
    const {
        obt_gateway,
        card_gateway, 
        gatewaysList,
        amount,
        base_amount,
        currency,
        base_currency,
        totalCharge,
        cashier_theme
    } = useSelector(({
        initTheme: { cashier_theme },
        gatewaysList,
        cardToObSettings: { card_gateway, obt_gateway } = {},
        payData: { amount, base_amount, currency, base_currency, totalCharge}
    }) => ({ 
        card_gateway, 
        obt_gateway, 
        gatewaysList,
        amount,
        base_amount,
        currency,
        base_currency,
        totalCharge,
        cashier_theme
    }), shallowEqual);

    const goBackToCard = ({ callback }) => {
        setIsLoading(true);

        dispatch(createCurrentGateway({
            option_id: card_gateway?.option_id,
            option_type: 'payment_method'
        })).then(() => {
            const updatedGatewayList = [card_gateway, ...gatewaysList.gateways];

            dispatch(changePayData({ 
                amount: amount || base_amount,
                currency: currency || base_currency,
                ...(totalCharge && {
                    totalCharge
                })
             }));

            const errors = validation({amount, currency}, ['amount'], isTwoFieldsAmount ? 'depositAmount' : 'amount');
            dispatch(createValidationPayError(errors));

            dispatch(updateGateways({ ...gatewaysList, gateways: updatedGatewayList }));

            dispatch(resetCardToObSettings());

            setIsLoading(false);

            if (cashier_theme === 'power-slide' && history && !callback) {
                history.goBack();
            }

            if (callback) {
                callback();
            }
        }).catch(() => {
            dispatch(resetCardToObSettings());
            setIsLoading(false);

            if (cashier_theme === 'power-slide' && history && !callback) {
                history.goBack();
            }

            if (callback) {
                callback();
            }
        });
    };

    return {
        goBackToCard
    }
}

export default useBackToCardButton;
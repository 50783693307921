import { useState } from "react";
import {withAdr} from "../../hoc-components/with-adr";
import {withI18N} from "../../hoc-components/hoc-context/with-i18n";
import compose from "../../../utils/helper-functions/compose";
import "./back-to-card-method.sass";
import Loader from "shared-components/helper-components/loader/loader";
import {withRouter} from "react-router";
import IconToggleArrow from "@pano-project/assets/icons/IconToggleArrow";
import {isPanoramicTheme} from "@pano-project/variables/is-panoramic-2-0-theme";
import useBackToCardButton from './use-back-tocard-button';

const BackToCardMethod = ({ s, langBack, history }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { goBackToCard } = useBackToCardButton({ setIsLoading, history })

    return (
        <div className={`${s('back-to-card__wrapper')}`}>
            <div className={`${s('back-to-card__button')} ${isLoading ? '_disabled' : ''}`} onClick={goBackToCard}>
                {isLoading ? <Loader smallLoader={true}/> : (isPanoramicTheme && <IconToggleArrow/>)}
                <span className={`${s('back-to-card__text')}`}>
                    {langBack('backToCard')}
                </span>
            </div>
        </div>
    );
};

export default compose(
    withI18N, withAdr, withRouter
)(BackToCardMethod)
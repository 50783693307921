import React from "react";
import {
  createCNPJFormatedValue,
  createCPFCNPJFormatedValue,
  createCPFFormatedValue,
  createEmailFormatedValue,
  createEVPFormatedValue,
  createPhoneFormatedValue,
  maskCNPJValue,
  maskCPFCNPJValue,
  maskCPFValue,
  maskEmailValue,
  maskEVPValue,
  maskPhoneValue,
} from "../utils/additional-fields-functions/formatted-masked-functions";
import { validateCPFCNPJ } from "../utils/validations-functions/validate-cpf-cnpj";
import { validateCPF } from "../utils/validations-functions/validate-cpf";
import { validateCNPJ } from "../utils/validations-functions/validate-cnpj";
import { validateEVP } from "../utils/validations-functions/validate-evp";
import CreateInputText from "../shared-components/helper-components/create-input/create-input-text";
import { validateEmail } from "../utils/validations-functions/validate-email";
import { validatePhoneNumber } from "../utils/validations-functions/validate-phone-number";

export const errorMsg = "Entrada inválida, corrija";
export const DEFAULT_PIX_KEY_TYPE = "cpf_cnpj";
export const DEFAULT_BANK_PIX_KEY_TYPE = "bank_account";

/**
 * @typedef {object} FieldData
 * @property {boolean} useInSelect - The flag to use the field in the select component.
 * @property {string} type - The type of the field.
 * @property {number} length - The length of the field.
 * @property {function} validationFunction - The function to validate the field value.
 * @property {function} formatFunction - The function to format the field value.
 * @property {function} maskFunction - The function to mask the field value.
 * @property {regExp} regexp - The regexp pattern for the field value.
 * @property {function} renderComponent - The component to render the field.
 * @property {boolean} isUseClearFunction - The flag to use the default clear function.
 * @property {object} uiTexts - Texts related to the field.
 * @property {string} uiTexts.label - The label text for the field.
 * @property {string} uiTexts.placeholder - The placeholder text for the field.
 * @property {string} uiTexts.error - The error text for the field.
 */
/**
 * Object containing additional fields data with validation, formatting, and masking functions.
 * @type {Object.<string, FieldData>}
 */
export const additionalFieldsData = {
  email: {
    type: "email",
    length: 255,
    validationFunction: validateEmail,
    formatFunction: createEmailFormatedValue,
    maskFunction: maskEmailValue,
    regexp:
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    renderComponent: (props) => <CreateInputText {...props} />,
    isUseClearFunction: false,
    uiTexts: {
      label: "Endereço de email",
      placeholder: "Insira o seu endereço de email",
      error: errorMsg,
    },
  },
  phone: {
    type: "phone",
    length: 30,
    validationFunction: validatePhoneNumber,
    formatFunction: createPhoneFormatedValue,
    maskFunction: maskPhoneValue,
    regexp:
      /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
    renderComponent: (props) => <CreateInputText {...props} />,
    isUseClearFunction: false,
    uiTexts: {
      label: "Número de celular",
      placeholder: "Digite seu número de celular",
      error: errorMsg,
    },
  },
  evp: {
    type: "evp",
    length: 32,
    validationFunction: validateEVP,
    formatFunction: createEVPFormatedValue,
    maskFunction: maskEVPValue,
    regexp: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
    renderComponent: (props) => <CreateInputText {...props} />,
    isUseClearFunction: true,
    uiTexts: {
      label: "Número EVP (aleatório)",
      placeholder: "Digite seu número EVP (aleatório)",
      error: errorMsg,
    },
  },
  cnpj: {
    type: "cnpj",
    length: 14,
    validationFunction: validateCNPJ,
    formatFunction: createCNPJFormatedValue,
    maskFunction: maskCNPJValue,
    regexp: /(\d{2})\.\d{3}\.\d{3}\/\d{4}-(\d{2})$/,
    renderComponent: (props) => <CreateInputText {...props} />,
    isUseClearFunction: true,
    uiTexts: {
      label: "Número do CNPJ",
      placeholder: "Digite seu número de CNPJ",
      error: errorMsg,
    },
  },
  cpf: {
    type: "cpf",
    length: 11,
    validationFunction: validateCPF,
    formatFunction: createCPFFormatedValue,
    maskFunction: maskCPFValue,
    regexp: /(\d{3})\.\d{3}\.\d{3}-(\d{2})$/,
    renderComponent: (props) => <CreateInputText {...props} />,
    isUseClearFunction: true,
    uiTexts: {
      label: "Número do CPF",
      placeholder: "Digite seu número de CPF",
      error: errorMsg,
    },
  },
  [DEFAULT_PIX_KEY_TYPE]: {
    type: DEFAULT_PIX_KEY_TYPE,
    length: 14,
    validationFunction: validateCPFCNPJ,
    formatFunction: createCPFCNPJFormatedValue,
    maskFunction: maskCPFCNPJValue,
    regexp:
      /(\d{3})\.\d{3}\.\d{3}-(\d{2})|(\d{2})\.\d{3}\.\d{3}\/\d{4}-(\d{2})$/,
    renderComponent: (props) => <CreateInputText {...props} />,
    isUseClearFunction: true,
    uiTexts: {
      label: "Número do CPF/CNPJ",
      placeholder: "Digite seu número de CPF/CNPJ",
      error: errorMsg,
    },
  },
};

export const localAdditionalFieldsTypes = Object.values(
  additionalFieldsData,
).map((el) => el.type);

export const bankAccountPixV2Payout = {
  bank_code: {
    key: "bank_code",
    uiTexts: {
      label: "Código do banco",
      placeholder: "Digite seu códão do banco",
      error: errorMsg,
    },
  },
  bank_branch: {
    key: "bank_branch",
    uiTexts: {
      label: "Código da agência",
      placeholder: "Digite seu código da agência",
      error: errorMsg,
    },
  },
  [DEFAULT_BANK_PIX_KEY_TYPE]: {
    key: DEFAULT_BANK_PIX_KEY_TYPE,
    uiTexts: {
      label: "Número da conta bancária",
      placeholder: "Digite seu número da conta bancária",
      error: errorMsg,
    },
  },
  bank_account_digit: {
    key: "bank_account_digit",
    uiTexts: {
      label: "Dígito da conta",
      placeholder: "Digite dígito da conta",
      error: errorMsg,
    },
  },
  bank_account_type: {
    key: "bank_account_type",
    uiTexts: {
      label: "Tipo de conta do titular",
      placeholder: "Digite tipo de conta do titular",
      error: errorMsg,
    },
  },
};

export const keysBankAccountPixV2Payout = Object.keys(
  bankAccountPixV2Payout,
).map((key) => key);

import { clearSymbols } from "../helper-functions/clear-number";
import { additionalFieldsData } from "../../variables/additional-fields-data";

/**
 * Validates a Brazilian CPF (Cadastro de Pessoas Físicas) number.
 * @param {string} cpf - The CPF number to validate.
 * @returns {boolean} - True if the CPF is valid, false otherwise.
 * @example
 * validateCPF("353.090.786-30");
 * // Returns true / false
 */
export const validateCPF = (cpf) => {
  const maskedValue = additionalFieldsData.cpf.maskFunction(cpf);
  if (maskedValue === cpf && maskedValue.includes("*")) {
    // if the value is already masked
    return true;
  }

  if (!additionalFieldsData.cpf.regexp.test(cpf)) {
    return false;
  }

  cpf = clearSymbols(cpf);

  if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
    return false;
  }

  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpf.charAt(9))) {
    return false;
  }

  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;

  return remainder === parseInt(cpf.charAt(10));
};

export type ICardToObSettings = {
  is_obt_supported?: 0 | 1;
  show_obt_banner?: boolean;
  show_back_to_card?: boolean;
  obt_gateway?: Record<string, any>;
  card_gateway?: Record<string, any>;
  psp_bank_id?: number
};

type IobSettingsAction = {
  type: 'CREATE_CARD_TO_OB_SETTINGS';
  payload: ICardToObSettings;
};

const createCardToObSettings = (
  state: { cardToObSettings: ICardToObSettings },
  action: IobSettingsAction
) => {
  if (state === undefined) {
    return false;
  }
  switch (action.type) {
    case 'CREATE_CARD_TO_OB_SETTINGS':
      return { ...state.cardToObSettings, ...action.payload };
    default:
      return state.cardToObSettings;
  }
};

export { createCardToObSettings };
